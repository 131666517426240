<template>
  <div id="exchanges">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 py-3 bg-lines bg-cover bg-fixed bg-center">

      <div class="md:container md:mx-auto">

        <p class="text-4xl mb-4 text-center">Hilfe-Center</p>

        <a href="https://hilfe.stadtbahnfahrer.club/" class="link group relative w-full flex justify-center py-2 px-4">
           Hilfe-Center öffnen <font-awesome-icon icon="arrow-alt-circle-right" class="icon my-auto mr-1" />
        </a>

        <meta http-equiv="refresh" content="1; URL=https://hilfe.stadtbahnfahrer.club/">

        <!-- <form action="https://api.stadtbahnfahrer.club/verification/upload" method="post" enctype="multipart/form-data">
          <input type="file" name="avatar" />
          <input type="submit" name="submit" />
        </form> -->

        
        <!-- <form action="https://api.stadtbahnfahrer.club/verification/upload" method="POST" enctype="multipart/form-data">
            <input type="file" name="filetoupload" required>
            <input type="submit" value="Upload">
        </form>

        <div class="bg-gray-800 p-6 mt-2 rounded-md flex flex-col md:flex-row gap-6">

          <div class="space-y-3">

            <p class="font-bold text-2xl">Häufig gestellte Fragen</p>
            <p>
              Du hast nicht die Antwort gefunden, die Du suchst?<br>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSca0IyZZxAfmkb9v3urR6dxp1VvpSK6a7-sVfxHVPJq8COplA/viewform?usp=pp_url" class="brand-color-dark font-bold">Kontaktiere uns gerne</a>.
            </p>

          </div>

          <div class="flex-1 space-y-6">

            <div><p class="font-bold text-lg">Wie lange dauert die Verifizierung?</p><p class="text-gray-300">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrdienst o.ä. Die Verifizierung kann bis zu 7 Tage dauern. Du kannst den Status jederzeit im Dashboard ansehen.</p></div>
            <div><p class="font-bold text-lg">Wie lange dauert die Verifizierung?</p><p class="text-gray-300">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrdienst o.ä. Die Verifizierung kann bis zu 7 Tage dauern. Du kannst den Status jederzeit im Dashboard ansehen.</p></div>
            <div><p class="font-bold text-lg">Wie lange dauert die Verifizierung?</p><p class="text-gray-300">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrdienst o.ä. Die Verifizierung kann bis zu 7 Tage dauern. Du kannst den Status jederzeit im Dashboard ansehen.</p></div>

          </div>

        </div>

        <div class="bg-gray-800 p-6 my-6 rounded-md flex flex-col md:flex-row gap-6">

          <div class="space-y-3">

            <p class="font-bold text-2xl">Häufig gestellte Fragen</p>
            <p>
              Du hast nicht die Antwort gefunden, die Du suchst?<br>
              <a href="" class="brand-color-dark font-bold">Kontaktiere uns gerne</a>.
            </p>

          </div>

          <div class="flex-1 space-y-6">

            <div><p class="font-bold text-lg">Wie lange dauert die Verifizierung?</p><p class="text-gray-300">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrdienst o.ä. Die Verifizierung kann bis zu 7 Tage dauern. Du kannst den Status jederzeit im Dashboard ansehen.</p></div>
            <div><p class="font-bold text-lg">Wie lange dauert die Verifizierung?</p><p class="text-gray-300">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrdienst o.ä. Die Verifizierung kann bis zu 7 Tage dauern. Du kannst den Status jederzeit im Dashboard ansehen.</p></div>
            <div><p class="font-bold text-lg">Wie lange dauert die Verifizierung?</p><p class="text-gray-300">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrdienst o.ä. Die Verifizierung kann bis zu 7 Tage dauern. Du kannst den Status jederzeit im Dashboard ansehen.</p></div>

          </div>

        </div>

        <div class="box-selection-option sm:text-left text-center p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col sm:flex-row gap-3  justify-between  items-center w-full h-full">
            <p><span class="font-bold">Du hast nicht die Antwort gefunden, die Du suchst?</span><br>Kontaktiere uns gerne: <i><a href="mailto:hallo@stadtbahnfahrer.club" class="link">per E-Mail</a></i> oder <i><a href="https://docs.google.com/forms/d/e/1FAIpQLSca0IyZZxAfmkb9v3urR6dxp1VvpSK6a7-sVfxHVPJq8COplA/viewform?usp=pp_url" class="link" target="_newTab">Feedback-Formular</a></i>.</p>
        
        </div> -->

      </div>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

import { mapGetters } from "vuex";

export default {
  name: "Exchanges",
  components: {
    SiteNav,
    TheHeader
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>